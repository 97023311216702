html {    overflow-y: scroll; }


html::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

html::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

html::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

html {    overflow-y: scroll; }

div.scroll2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(255,255,255);
	background-color: #F5F5F5;
}

div.scroll2::-webkit-scrollbar
{
	width: 3px;
  background-color: #FFFFFF;
  cursor: pointer;
}

div.scroll2::-webkit-scrollbar-thumb
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
  cursor: pointer;
}

.app-select-icon{
    width: 38px;
}

.app-select-icon2{
  width: 70px;
}

.card-body-min-height{
    min-height: 300px;
}
.input2{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bd-container{
    max-height: 200px;
}

.bl-danger{
    border-left: 6px solid   #dc3545 !important;
}

.bl-warning{
    border-left: 6px solid   #ffc107 !important;
}

.drop-item-warning{
    border-left: 6px solid   #ffc107 !important;
}

.drop-item-warning:hover{
    border-right: 6px solid   #ffc107 !important;
}


.drop-item-danger{
    border-left: 6px solid   #dc3545 !important;
}

.drop-item-danger:hover{
    border-right: 6px solid   #dc3545 !important;
}

.driver-td-action{
    width: 183px;
}

.icon-drop-no button.dropdown-toggle::after{
    display: none !important;
}

.dropdown-item{
    cursor:pointer;
}

.company-logo-view{
    max-height: 155px;
    cursor:pointer;
    margin: 2px;
}

.app-logo2{
    
    width: 100%;
    height: 100%;
}
.app-logo2 img{
    max-height: 40px
}

.story-highlight{
    border-bottom: 1px;
    border-bottom: 2px dotted;
    border-bottom: medium solid rgba(220,220,220,.5); 
}

.story-item-icon{
    width: 40px;
}

.timeline-badge{
    width:33px;
}

.story-max-h{
    max-height: 300px;
}

a.g-link{
    display: none;
}

span.geo:hover a.g-link{
    display: inline;
    font: inherit !important;
}

.trip-sc-tl .ti{
    width: 80px;
    display: inline-block;
}


.trip-sc-tl .tt{
    width: 55px;
    display: inline-block;
}
.map-info{
    color: #495057;
}
.map-info ul{
    list-style-type: none;
}


#markerLayer img {
    animation: pulse .5s infinite alternate;
    -webkit-animation: pulse .5s infinite alternate;
    transform-origin: center;
    -webkit-transform-origin: center;
  }
  
  .bg-test{
      background-color: #fff;
  }

  .w-100 svg{
      width: 100% !important;
  }

  .good{
    color:  #5ea758;
  }


  .bad{
    color:  #f54029;
  }

  .live-tile{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
  }

  .tile-running{
    background-color: #28a745;
  }


  .tile-idle{
    background-color: #e04a49;
  }

  .tile-off{
    background-color: #969696;
  }


  .tile-no-data{
    background-color: #17a2b8;
  }

  .wait {cursor: wait;}
  
  .w-dp{
      width: 100px;
  }
  #chartContainerABC{
    /*height: 400px; width: 100%;*/
  }

  .progress-banner .geo a i{
    font-size: 1rem;
  }
 
  .repo-sel__value-container--has-value .repo-sel__multi-value__label img{
    display: none;
  }


  .repo-sel__value-container--has-value .repo-sel__multi-value__label span{
    display: none;
  }

.rounded .card {
    border-radius: .75rem;
}

.chart-min-w{
    min-width: 200px;
}

.login-form{
    
    background: url("../img/pcb-23.jpg") no-repeat center top !important;
    background-position: -10px -107px !important;
    border-radius: .75rem;
    
}

.bg-none{
    background: none !important;
}

.border-radius-75{
    border-radius: .75rem;
}


.filterlist ul {
    list-style-type: none;
  }
  
  .filterlist ul li {
    margin: 0 0 20px;
    border-bottom: 1px solid #ccc;
    padding: 0 0 20px;
  }
  
  .filterlist ul li:last-child {
    margin: 0;
  }
  
  .filterlist img {
    float: left;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 10px 0 0;
  }
  
  .filterlist span.label {
    display: block;
    width: 100%;
    font-weight: bolder;
  }
  
  .filterlist span.phone {
    font-weight: normal;
    font-style: italic;
    color: #999;
  }

  .navbar .navbar-logo .logo-mobile2 {
    width: 100%;
    height: 100%;
 
}

.logo-mobile2 img{
  width: 100px;
}

.dashboard-map {
  height: auto;
  max-height: 475px;
  overflow: auto;
}

.bl-running{
  
  border-left: 6px solid   #28a745 !important;
  cursor: pointer;
  margin-bottom: 2px;
}

.bl-idle{
  border-left: 6px solid   #dc3545 !important;
  cursor: pointer;
  margin-bottom: 2px;
}

.bl-off{
  border-left: 6px solid #969696 !important;
  cursor: pointer;
  margin-bottom: 2px;
}

.bg-dark2{
  background-color: #969696 !important;
}

.bl-data{
  border-left: 6px solid   #17a2b8 !important;
  margin-bottom: 2px;
}

.bl-no-data{
  border-left: 6px solid   #17a2b8 !important;
  margin-bottom: 2px;
}

.d-line-keeper{
  min-width: 115px;
  max-width: 200px;
  font-weight: 600;
}

.rs-dark__control{
  background: #313131 !important;
  border-width: 0 !important;
}

.rs-dark__multi-value{
  background-color: #232223 !important;
}

.rs-dark__multi-value span{
  color: #969696 !important;

}

.rs-dark__multi-value strong{
  color: #969696 !important;
}

.rs-dark__menu{
  background-color: #232223 !important;
}

.rs-dark__option:hover{
  background-color: #313131 !important;
}

.overflow-h{
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.bg-m1{
  background: #faebd7 !important;
}

.bg-m2{
  background: #deb887 !important;
}

.dashboard-top10{
  height: 301px;
}

.rs__multi-value img{
  display: none;
}
.rs__multi-value span{
  display: none;
}

.bg-yellow{
  background-color: #ffff14 !important;
    color: #000 !important;
}


.ExcelTable2007 {
	border: 1px solid #B0CBEF;
	border-width: 1px 0px 0px 1px;
	font-size: 11pt;
	font-family: Calibri;
	font-weight: 100;
	border-spacing: 0px;
	border-collapse: collapse;
	padding: 10px;
}

.ExcelTable2007 TH {
	/*background-image: url("../img/excel-2007-header-bg.gif");*/
	background-repeat: repeat-x; 
	font-weight: bold;
	font-size: 14px;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	height: 17px;
	line-height: 17px;
}

.ExcelTable2007 TD {
	border: 0px;
	background-color: white;
	padding: 0px 4px 0px 2px;
	border: 1px solid #D0D7E5;
	border-width: 0px 1px 1px 0px;
}

.ExcelTable2007 TD B {
	border: 0px;
	background-color: white;
	font-weight: bold;
}

.ExcelTable2007 TD.heading {
	background-color: #E4ECF7;
	text-align: center;
	border: 1px solid #9EB6CE;
	border-width: 0px 1px 1px 0px;
	font-weight: bold;
}

.ExcelTable2007 TH.heading {
	/* background-image: url("../img/excel-2007-header-left.gif"); */
	background-repeat: none;
}

.nav-item{
  cursor: pointer;
}